.timer {
    min-width: 180px;
    font-size: 34px;
    font-weight: bold;
    margin-right: 20px;
}

.root {
    text-align: center;
    flex-direction: column;
    display: flex;
}

.header {
    font-weight: bold;
    font-size: 20px;
    color: #203152;
    background-color: #f5a623;
    padding: 20px;
    box-shadow: 0 4px 4px #808888;
    flex-direction: row;
}

.body {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    min-height: 75vh;
}

.viewLoading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.icLogout {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 0;
    margin-right: 30px;
    cursor: pointer;
}

.icProfile {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 0;
    margin-right: 80px;
    cursor: pointer;
}

/* Dialog confirm */

.viewCoverScreen {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.viewWrapTextDialogConfirmLogout {
    width: 300px;
    height: 50px;
    background-color: #f5a623;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center
}

.titleDialogConfirmLogout {
    color: white;
    font-weight: bold;
    font-size: 18px;
}

.viewWrapButtonDialogConfirmLogout {
    flex-direction: row;
    height: 100px;
    width: 300px;
    min-width: 300px;
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.btnYes {
    width: 100px;
    height: 40px;
    font-weight: bold;
    margin-right: 20px;
    background-color: #203152;
    border: 0px;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.btnNo {
    width: 100px;
    height: 40px;
    font-weight: bold;
    margin-left: 20px;
    padding: 5px;
    background-color: #dd4b39;
    border: 0px;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

/* List user */

.viewListUser {
    overflow-y: scroll;
    max-height: 90vh;
    padding: 20px 20px 10px 20px;
}

.viewListUser::-webkit-scrollbar-track {
    padding: 2px 0;
    background-color: #e8e8e8;
}

.viewListUser::-webkit-scrollbar {
    width: 6px;
}

.viewListUser::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #203152;
}

.viewWrapItem {
    border: none;
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    // width: 330px;
    padding: 10px;
    align-items: center;
    justify-content: center;
}

.viewWrapItemFocused {
    border: none;
    display: flex;
    flex-direction: row;
    background-color: #e8e8e8;
    width: 330px;
    padding: 10px;
    align-items: center;
    justify-content: center;
}

.viewAvatarItem {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    object-fit: cover;
}

.viewWrapContentItem {
    flex-direction: column;
    display: flex;
    flex: 1;
    margin-left: 15px;
    color: #203152;
    word-wrap: break-word;
}

.textItem {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    // width: 240px;
    text-align: left;
    font-size: 14px;
}

.viewBoard {
    display: flex;
    flex: 1;
    max-height: 75vh;
}




/* Chat board */

.viewChatBoard {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.headerChatBoard {
    border-bottom: 1px solid #e8e8e8;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.headerChatUser {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.headerChatButton {
    display: flex;
}

.textHeaderChatBoard {
    font-weight: bold;
    color: #203152;
    margin-left: 10px;
}

.viewListContentChat {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: scroll;
    padding: 10px 20px 20px 20px;
}

.viewListContentChat::-webkit-scrollbar-track {
    padding: 2px 0;
}

.viewListContentChat::-webkit-scrollbar {
    width: 6px;
}

.viewListContentChat::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #e8e8e8;
}

.viewBottom {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 50px;
    border-top: 1px solid #e8e8e8;
    padding-right: 20px;
}

.icOpenGallery {
    width: 30px;
    height: 30px;
    margin-left: 10px;
}

.viewInputGallery {
    opacity: 0;
    position: absolute;
    z-index: -1;
    left: 10px;
    width: 30px;
}

.icOpenSticker {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-right: 5px;
}

.icSend {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-right: 5px;
}

.viewInput {
    flex: 1;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    border: 0px;
    height: 30px;
    font-size: 20px;
}

.viewInput:focus {
    outline: 0;
}

input::placeholder {
    color: rgb(199, 199, 199);
}

/* View item message */

.viewItemRight {
    width: 300px;
    height: auto;
    background-color: #e8e8e8;
    align-self: flex-end;
    margin-right: 20px;
    margin-top: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    color: #203152;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.viewItemRight2 {
    width: 300px;
    height: auto;
    background-color: #e8e8e8;
    align-self: flex-end;
    margin-right: 20px;
    margin-top: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    color: #203152;
    text-align: left;
}

.viewItemRight3 {
    width: 300px;
    height: auto;
    align-self: flex-end;
    margin-right: 20px;
    margin-top: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    text-align: left;
}

.viewWrapItemLeft {
    width: 300px;
    text-align: left;
    align-self: flex-start;
    margin-left: 20px;
    margin-top: 10px;
}

.viewWrapItemRight {
    width: 300px;
    text-align: left;
    align-self: flex-end;
    margin-left: 20px;
    margin-top: 10px;
}

.viewWrapItemLeft2 {
    width: 300px;
    align-self: flex-start;
    text-align: left;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: -15px;
}

.viewWrapItemLeft3 {
    flex-direction: row;
    display: flex;
    width: 300px;
}

.viewWrapItemRight3 {
    flex-direction: row;
    display: flex;
    width: 300px;
}

.viewItemLeft {
    width: 300px;
    height: auto;
    background-color: #203152;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
}

.viewItemLeft2 {
    width: 300px;
    height: auto;
    background-color: #203152;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
    color: white;
}

.viewItemLeft3 {
    width: 300px;
    height: auto;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
    color: white;
}

.peerAvatarLeft {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    margin-right: 10px;
    object-fit: cover;
    align-self: flex-end;
}

.viewPaddingLeft {
    width: 40px;
}

/* Item text */

.textContentItem {
    font-size: 14px;
    word-break: break-all;
}

/* Item image */

.imgItemRight {
    object-fit: cover;
    width: 300px;
    height: 300px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
}

.imgItemLeft {
    object-fit: cover;
    width: 300px;
    height: 300px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
}

.textTimeLeft {
    color: #808888;
    font-size: 12px;
    font-style: italic;
    margin-left: 50px;
}

/* Stickers */

.viewStickers {
    display: flex;
    border-top: 1px solid #e8e8e8;
    height: 100px;
    align-items: center;
    justify-content: space-around;
}

.imgSticker {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

/* Say hi */

.viewWrapSayHi {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.textSayHi {
    color: #808888;
    font-size: 14px;
}

.imgWaveHand {
    width: 40px;
    height: 40px;
    margin-left: 10px;
}