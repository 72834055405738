.service-detail-page {
  .service-title {
    margin-bottom: 5px;
  }

  .service-category {
    margin: 10px;
  }

  .service-price {
    font-size: 40px;
  }

  .service-description {
    font-size: 21px;
  }

  .service-header-container {
    display: flex;
  }

  .service-user {
    margin-bottom: 30px;
    flex: 1;
  }

  .user-container {
    text-align: left !important;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .user-image {
        margin-right: 20px;
    }

    .user-name {
        font-size: 21px;
    }
  }
}

// SERVICE CREATE
.create-page {
  margin-top: 70px;
  margin-bottom: 70px;

  .form-container {
    max-width: 860px;
    margin: 0 auto;
  }

  .field.is-grouped {
    margin-top: 20px;
  }
}
// SERVICE CREATE ENDS