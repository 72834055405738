/* ==========================================================================
Color variables
========================================================================== */

$white: #fff;
$smoke-white: #fcfcfc;
$grey-white: #f2f2f2;

$primary: #4fc1ea;
$secondary: #f39200;
$accent: #00efb7;

$fade-grey: #ededed;
$light-grey: #eff4f7;
$title-grey: #a9abac;
$blue-grey: #444f60;
$muted-grey: #999;
$light-blue-grey: #98a9c3;
$medium-grey: #66676b;
$basaltic-grey: #878787;
$purple: #7f00ff;
$mint: #11ffbd;
$bloody: #fc354c;
$pinky: #ff00cc;
$frost: #004e92;
$placeholder: #cecece;
$dark-grey: #344258;
$border-grey: #ccc;
$muted-grey: #999;
$section-grey: #fbfbfb;

.s-m-r {
  margin-right: 5px;
}

@import './styles/auth.scss';
@import './styles/services.scss';
@import './styles/collaborations.scss';

.navbar-dropdown-messages {
  min-width: 320px !important;

  .from-user {
    padding-left: 20px;
    margin-bottom: 10px;

    span {
      font-weight: bold;
    }
  }

  hr {
    margin: 0;
  }

  .navbar-item-message {
    white-space: inherit;
  }
}

.feature-card.offer-card {
  height: auto;
  padding-bottom: 20px;
}

// MODAL
.modal {
  text-align: left !important;
}

/* ==========================================================================
General Keyframes animations
========================================================================== */

.animated {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
}

//Delays
.delay-1 {
  animation-delay: 0.25s;
}
.delay-2 {
  animation-delay: 0.5s;
}
.delay-3 {
  animation-delay: 0.75s;
}
.delay-4 {
  animation-delay: 1s;
}

// FADE IN LEFT
@keyframes fadeInLeft {
  from {
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes fadeInLeft {
  from {
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.preFadeInLeft {
  opacity: 0;
}

.fadeInLeft {
  opacity: 0;
  animation-name: fadeInLeft;
  -webkit-animation-name: fadeInLeft;
}

// FADE IN UP
@keyframes fadeInUp {
  from {
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes fadeInUp {
  from {
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.preFadeInUp {
  opacity: 0;
}
.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

//Gelatine
.gelatine {
  animation: gelatine 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.5s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}

@keyframes gelatine {
  from,
  to {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}

/* ==========================================================================
Classes to change the feel of bulma buttons
========================================================================== */

// CTA buttons

.button {
  cursor: pointer;
  transition: all 0.5s;
  &.cta {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    padding: 26px 40px 26px 40px;
  }
  &.is-clear {
    line-height: 0 !important;
  }
  &.rounded {
    border-radius: 500px;
  }
  &.raised:hover {
    box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.42),
      0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2) !important;
    opacity: 0.8;
  }
  &.btn-outlined {
    background: transparent;
  }
  &.signup-button {
    font-size: 0.9rem;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    padding: 24px 26px;
    width: 130px;
  }
}

.button {
  &.primary-btn {
    outline: none;
    border-color: $primary;
    background-color: $primary;
    color: $white;
    transition: all 0.5s;
    &:hover {
      color: $white;
    }
    &.raised:hover {
      box-shadow: 0 14px 26px -12px rgba(79, 193, 234, 0.42),
        0 4px 23px 0px rgba(0, 0, 0, 0.12),
        0 8px 10px -5px rgba(79, 193, 234, 0.2) !important;
      opacity: 0.8;
    }
    &.btn-outlined {
      border-color: $primary;
      color: $primary;
      background-color: transparent;
      &:hover {
        color: $white;
        background-color: $primary;
      }
    }
  }
  &.secondary-btn {
    outline: none;
    border-color: $secondary;
    background-color: $secondary;
    color: $white;
    transition: all 0.5s;
    &:hover {
      color: $white;
    }
    &.raised:hover {
      box-shadow: 0 14px 26px -12px rgba(243, 146, 0, 0.42),
        0 4px 23px 0px rgba(0, 0, 0, 0.12),
        0 8px 10px -5px rgba(243, 146, 0, 0.2) !important;
      opacity: 0.8;
    }
    &.btn-outlined {
      border-color: $secondary;
      color: $secondary;
      background-color: transparent;
      &:hover {
        color: $white;
        background-color: $secondary;
      }
    }
  }
  &.button.accent-btn {
    outline: none;
    border-color: $accent;
    background-color: $accent;
    color: $white;
    transition: all 0.5s;
    &:hover {
      color: $white;
    }
    &.raised:hover {
      box-shadow: 0 14px 26px -12px rgba(104, 187, 136, 0.42),
        0 4px 23px 0px rgba(0, 0, 0, 0.12),
        0 8px 10px -5px rgba(104, 187, 136, 0.2) !important;
      opacity: 0.8;
    }
    &.btn-outlined {
      border-color: $accent;
      color: $accent;
      background-color: transparent;
      &:hover {
        color: $white;
        background-color: $accent;
      }
    }
  }
}

.icon {
  &.icon.accent-icon {
    outline: none;
    border-color: $secondary;
    // background-color: $accent;
    color: $secondary;
  }
}
/*! _cards.scss v1.0.0 | Commercial License | built on top of bulma.io/Bulmax */

/* ==========================================================================
Cards and Card content styles
========================================================================== */

// Feature Card
.feature-card {
  width: 300px;
  height: 320px;
  background-color: #fff;
  border-radius: 3px;
  margin: 0 auto 50px auto;

  .card-title h4 {
    font-family: 'Open Sans', sans-serif;
    padding-top: 25px;
    font-size: 1.2rem;
    font-weight: 600;
    color: $blue-grey;
  }

  .card-icon img {
    height: 120px;
    margin-top: 20px;
  }

  .card-text {
    padding: 0 40px;

    p {
      color: $muted-grey;
    }
  }

  .card-action {
    margin-top: 10px;
  }

  &.is-bordered {
    border: 1px solid $fade-grey;
  }
}

// Flex Card
.flex-card {
  position: relative;
  background-color: #fff;
  border: 0;
  border-radius: 0.1875rem;
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: 20px;

  &.raised {
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  }

  .tabs {
    padding: 15px 0.7rem;
  }

  .navtab-content {
    min-height: 190px;

    p {
      padding: 0 0.8rem 20px;
    }
  }

  .navigation-tabs {
    &.outlined-pills .tabs.tabs-header {
      &.primary {
        background-color: $primary;
      }

      &.secondary {
        background-color: $secondary;
      }

      &.accent {
        background-color: $accent;
      }

      ul li a {
        color: $grey-white;
      }

      ul li.is-active a {
        color: $white;
        border: 1px solid $white;
        border-bottom-color: $white !important;
      }
    }
  }
}

//Modal card
.modal {
  .auth-card {
    max-width: 460px;
    margin: 0 auto;
    border-radius: 6px;

    .tabs {
      margin-bottom: 0;

      li {
        a {
          color: $placeholder;
        }

        &.is-active {
          a {
            color: $secondary;
            border-bottom-color: $secondary;
          }
        }
      }
    }

    .tab-content {
      padding: 20px;

      .field {
        max-width: 390px;
        margin: 10px auto;

        label {
          display: block;
          font-weight: 500;
          font-size: 0.9rem;
        }

        .input {
          font-size: 0.95rem;
          height: 44px;
        }
      }

      .button.is-fullwidth {
        padding: 20px 0;
        max-width: 390px;
        margin: 20px auto;
      }
    }
  }
}

/* ==========================================================================
Dropdown styles
========================================================================== */

// Hover Dropdowns
div.nav-item.is-drop a {
  padding-right: 7px;
}

div.nav-item.is-drop:hover .dropContain .dropOut {
  opacity: 1;
}

div.nav-item.is-drop:hover,
div.nav-item.is-drop:hover a {
  border-bottom: 1px solid transparent !important;
  color: $secondary;
}

div.nav-item.is-drop:hover .dropContain {
  top: 65px;
  animation: fadeInUp 0.27s ease-out;
}

span.drop-caret {
  position: relative;
  top: 5px;
}

div.nav-item.is-drop {
  position: relative;
  .dropContain {
    width: 220px;
    position: absolute;
    z-index: 3;
    left: 50%;
    margin-left: -110px; /* half of width */
    top: -400px;
    .dropOut {
      width: 220px;
      background: $white;
      float: left;
      position: relative;
      margin-top: 15px;
      opacity: 0;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
      -webkit-transition: all 0.5s ease-out;
      -moz-transition: all 0.5s ease-out;
      -ms-transition: all 0.5s ease-out;
      -o-transition: all 0.5s ease-out;
      transition: all 0.5s ease-out;
    }
    .dropOut .triangle {
      width: 0;
      height: 0;
      position: absolute;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid $white;
      top: -8px;
      left: 50%;
      margin-left: -8px;
    }
    .dropOut ul li {
      text-align: left;
      float: left;
      width: 200px;
      padding: 12px 0 10px 15px;
      margin: 0px 10px;
      color: #777;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      -webkit-transition: background 0.1s ease-out;
      -moz-transition: background 0.1s ease-out;
      -ms-transition: background 0.1s ease-out;
      -o-transition: background 0.1s ease-out;
      transition: background 0.1s ease-out;
      &:hover {
        background: $light-grey;
        cursor: pointer;
      }
    }
    .dropOut ul {
      float: left;
      padding: 10px 0;
    }
  }
}

/* ==========================================================================
Fresh Footer
========================================================================== */

footer.footer-dark {
  background: $blue-grey;
  color: $white;
  .columns {
    margin-top: 35px;
  }
  @media (max-width: 767px) {
    height: auto;
  }
  .footer-logo {
    img {
      height: 40px;
    }
  }
  .footer-column {
    .footer-header h3 {
      font-weight: 500;
      font-size: 1.2rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 20px;
    }
    ul.link-list {
      line-height: 40px;
      font-size: 1.1rem;
      a {
        color: $light-blue-grey;
        font-weight: 400;
        transition: all 0.5s;
      }
      :hover {
        color: $smoke-white;
      }
    }
    .level-item .icon {
      color: $secondary;
      transition: all 0.5s;
      :hover {
        color: $smoke-white;
      }
    }
  }
}

/* ==========================================================================
Inputs styles
========================================================================== */

input.input {
  color: $basaltic-grey;
  box-shadow: none !important;
  transition: all 0.8s;
  padding-bottom: 3px;
  &.is-small {
    padding-bottom: 2px;
    padding-left: 10px;
  }
  &.is-medium {
    padding-bottom: 5px;
  }
  &.is-large {
    padding-bottom: 7px;
  }
  &:focus,
  &:active {
    border-color: $light-grey;
  }
  &.rounded {
    border-radius: 100px;
  }
  &.is-primary-focus:focus {
    border-color: $primary;
    ~ span.icon i {
      color: $primary;
    }
  }
  &.is-secondary-focus:focus {
    border-color: $secondary;
    ~ span.icon i {
      color: $secondary;
    }
  }
  &.is-accent-focus:focus {
    border-color: $accent;
    ~ span.icon i {
      color: $accent;
    }
  }
  &.is-bloody-focus:focus {
    border-color: $bloody;
    ~ span.icon i {
      color: $bloody;
    }
  }
}

.form-footer {
  width: 100%;
}

/* ==========================================================================
Hero styles
========================================================================== */

.hero-body {
  padding-top: 6rem;
  padding-bottom: 6rem;
  .title,
  .subtitle {
    font-family: 'Open Sans', sans-serif;
  }
  .title {
    &.is-bold {
      font-weight: 700;
    }
  }
  .subtitle {
    &.is-muted {
      color: $muted-grey;
    }
  }
}

.hero-foot {
  img.partner-logo {
    height: 70px;
  }
}

/* ==========================================================================
Navbar
========================================================================== */

//Navbar
.navbar.is-fresh {
  position: relative;
  min-height: 3.8rem;
  transition: all 0.3s;
  z-index: 29;
  .container {
    min-height: 4rem;
  }
  &.no-shadow {
    box-shadow: none !important;
  }
  //Responsive menu icon
  .navbar-burger {
    width: 4rem;
    height: 4rem;
  }
  //Brand
  .navbar-brand {
    min-height: 4rem;
    img {
      max-height: 36px !important;
      height: 36px;
    }
    //Removing navbar item default hover behaviour
    &:hover {
      .navbar-item {
        background: transparent !important;
      }
    }
  }
  .navbar-end {
    align-items: center;
  }
  //Navbar items
  .navbar-item {
    color: $muted-grey;
    &.is-secondary {
      &:hover {
        color: $secondary !important;
      }
    }
    &.has-dropdown {
      padding: 10px 0;
      .navbar-link {
        color: $muted-grey;
        &:after {
          top: 55%;
          height: 0.5em;
          width: 0.5em;
          border-width: 2px;
          border-color: $muted-grey;
        }
      }
      .navbar-dropdown {
        top: 3.4rem;
        min-width: 220px;
        margin-top: 4px;
        border-top-color: $secondary;
        .navbar-item {
          padding: 10px 20px;
        }
      }
      &:hover {
        .navbar-link {
          color: $secondary;
          &:after {
            border-color: $secondary;
          }
        }
      }
    }
    .signup {
      display: block;
      line-height: 0;
      font-size: 0.9rem !important;
    }
  }

  //Fixed navbar modifier
  &.is-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 4rem !important;
    background: $white;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    a {
      color: $blue-grey;
      &:hover {
        color: $primary;
      }
    }
  }
}

//Cloned fixed navbar
#navbar-clone {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $white;
  transform: translateY(-100%);
  z-index: 100;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  &.is-active {
    transform: translateY(0);
  }
}

/* ==========================================================================
Responsive Styles
========================================================================== */

@media (max-width: 767px) {
  .landing-caption {
    text-align: center;
  }

  .navbar-menu {
    .is-static {
      position: absolute;
      width: 100%;
    }

    .is-fixed {
      position: fixed;
      width: 100%;
    }

    .navbar-item {
      text-align: center !important;

      .signup-button {
        width: 100% !important;
      }
    }

    .navbar-link {
      padding: 10px 20px !important;
    }
  }

  .title.section-title {
    font-size: 2rem !important;
  }

  .level-left.level-social {
    display: flex;
    justify-content: flex-start;
  }

  .pushed-image {
    margin-top: 0 !important;
  }

  .testimonial {
    margin: 0 auto;

    blockquote {
      font-size: 1rem;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .landing-caption {
    text-align: center;
    .subtitle {
      max-width: 440px;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }

  form {
    padding: 0 40px;
  }

  .hero-body {
    padding-bottom: 0;
    img {
      display: block;
      margin: 0 auto;
      max-height: 450px !important;
      max-width: 450px !important;
    }
  }

  .navbar-menu {
    .is-static {
      position: absolute;
      width: 100%;
    }

    .is-fixed {
      position: fixed;
      width: 100%;
    }

    .navbar-item {
      text-align: center !important;

      .signup-button {
        width: 100% !important;
      }
    }

    .navbar-link {
      padding: 10px 20px !important;
    }
  }

  .pushed-image {
    margin-top: 0 !important;
  }

  .testimonial {
    margin: 0 auto;
  }

  .is-centered-tablet-portrait {
    text-align: center !important;

    .divider {
      margin: 0 auto !important;
    }
  }
  .footer-logo,
  .footer-column {
    text-align: center;
  }
  .level.is-mobile {
    justify-content: center !important;
    .level-item {
      margin: 0 0.75rem !important;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .navbar-menu {
    .navbar-end {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .navbar-link {
        padding-right: 0 !important;
      }
      .button {
        min-width: 180px;
      }
    }
  }

  .navbar-item {
    &.is-hidden-mobile {
      display: none !important;
    }
    &.is-hidden-desktop.is-hidden-tablet {
      display: flex !important;
    }
  }

  .pushed-image {
    margin-top: 0 !important;
  }
}

/* ==========================================================================
Section Styles
========================================================================== */

//Sections
.section {
  &.section-light-grey {
    background-color: $light-grey;
  }
  &.section-feature-grey {
    background-color: $section-grey;
  }
  &.section-secondary {
    background-color: $secondary;
  }
  &.section-half {
    height: 75vh !important;
  }
  .title,
  .subtitle {
    font-family: 'Open Sans', sans-serif;
  }
  .subtitle {
    &.is-muted {
      color: $muted-grey;
    }
  }
}

//Titles
.title-wrapper {
  max-width: 500px;
  margin: 0 auto;
  .title,
  .subtitle {
    font-family: 'Open Sans', sans-serif;
  }
  .subtitle {
    &.is-muted {
      color: $muted-grey;
    }
  }
}

//Divider
.divider {
  height: 3px;
  border-radius: 50px;
  background: $secondary;
  width: 60px;
  &.is-centered {
    margin: 0 auto;
  }
}

//Wrapper
.content-wrapper {
  padding: 60px 20px;
}

//Pulled image
img.pushed-image {
  margin-top: -29vh;
}

//Icon box
.media.icon-box {
  border-top: none !important;
  .media-content .content p {
    span {
      display: block;
    }
    .icon-box-title {
      color: $blue-grey;
      font-size: 1.2rem;
      font-weight: 600;
    }
    .icon-box-text {
      color: $title-grey;
      font-size: 1rem;
      font-weight: 400;
    }
  }
}

/* ==========================================================================
Sidebar Styles
========================================================================== */

//Sidebar animated icon trigger
.menu-icon-wrapper {
  position: relative;
  left: 0;
  top: 0;
  //margin: -12px 0px 0px -12px;
  width: 34px;
  height: 34px;
  pointer-events: none;
  transition: 0.1s;
  svg {
    position: absolute;
    top: -18px;
    left: -18px;
    transform: scale(0.07);
    transform-origin: 0 0;
    path {
      stroke: $secondary;
      stroke-width: 40px;
      stroke-linecap: round;
      stroke-linejoin: round;
      fill: transparent;
      transition: stroke-dasharray 0.5s;
      &.path1 {
        stroke-dashoffset: 5803.15px;
        stroke-dasharray: 2901.57px, 2981.57px, 240px;
      }
      &.path2 {
        stroke-dashoffset: 800px;
        stroke-dasharray: 400px, 480px, 240px;
      }
      &.path3 {
        stroke-dashoffset: 6993.11px;
        stroke-dasharray: 3496.56px, 3576.56px, 240px;
      }
    }
  }
  &.open {
    svg {
      path {
        &.path1 {
          stroke-dasharray: 2901.57px, 5258.15px, 240px;
        }
        &.path2 {
          stroke-dasharray: 400px, 600px, 0px;
        }
        &.path3 {
          stroke-dasharray: 3496.56px, 6448.11px, 240px;
        }
      }
    }
  }
  .menu-icon-trigger {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    pointer-events: auto;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
  }
}

//Sidebar
.sidebar {
  background: $dark-grey;
  width: 280px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-281px);
  transition: all 0.3s;
  z-index: 10000;
  overflow-y: auto;
  &.is-active {
    transform: translateX(0);
  }
  .sidebar-header {
    height: 4.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid lighten($dark-grey, 5%);
    padding: 0 20px;
    img {
      height: 32px;
    }
    a {
      width: 24px;
      height: 24px;
    }
    svg {
      stroke: $white;
      transform: rotate(0);
      transition: all 0.3s;
      cursor: pointer;
      &:hover {
        stroke: $secondary;
        transform: rotate(180deg);
      }
    }

    .sidebar-title {
      color: white;
      font-weight: bold;
      font-size: 26px;
    }
  }
  .inner {
    position: relative;
    .sidebar-menu {
      margin: 0;
      padding: 0;
      max-width: 400px;
      list-style: none;
      list-style-type: none;
      font-family: 'Open Sans', sans-serif !important;
      li {
        a {
          padding: 20px 25px;
          display: block;
          text-decoration: none;
          color: $white;
          &:hover {
            padding: 20px 25px;
            display: block;
            text-decoration: none;
            color: $white;
          }
        }
        a span {
          margin-right: 20px;
          color: $white;
        }
        &.have-children {
          ul {
            padding: 0px;
          }
          li {
            a {
              background-color: darken($dark-grey, 5%);
              padding-left: 62px;
              border-bottom: 1px solid darken($dark-grey, 2%);
              font-size: 0.8rem;
              &:hover {
                color: $primary;
                padding-left: 62px;
              }
            }
          }
          span::after {
            position: absolute;
            top: 27px;
            right: 30px;
            content: '\f054';
            color: $white;
            transition: all 0.5s;
            font-weight: 200 !important;
            font-size: 0.8rem;
          }
        }
      }
    }
    li.have-children,
    li {
      position: relative;
    }
    li.have-children.active > a,
    li.have-children.active > a span,
    li.have-children.active > a span:after {
      color: $secondary;
    }
    li.active.have-children span::after {
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }
    .sidebar-menu .have-children > ul {
      display: none;
    }
  }
}

/* ==========================================================================
Testimonials Styles
========================================================================== */

.testimonial {
  position: relative;
  overflow: hidden;
  margin: 10px auto;
  min-width: 220px;
  max-width: 310px;
  width: 100%;
  color: #333;
  text-align: left;
  box-shadow: none !important;
  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  img {
    max-width: 100%;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    margin-right: 5px;
    display: block;
    z-index: 1;
    position: absolute;
    right: 60%;
  }
  blockquote {
    margin: 0;
    display: block;
    border-radius: 8px;
    position: relative;
    background-color: $smoke-white;
    padding: 30px 50px 65px 50px;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0 0 -40px;
    line-height: 1.6em;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  }
  blockquote:before,
  blockquote:after {
    font-family: 'FontAwesome';
    content: '\f10d';
    position: absolute;
    font-size: 20px;
    opacity: 0.3;
    font-style: normal;
  }
  blockquote:before {
    top: 35px;
    left: 20px;
  }
  blockquote:after {
    content: '\f10e';
    right: 20px;
    bottom: 35px;
  }
  .author {
    margin: 0;
    height: 80px;
    display: block;
    text-align: left;
    color: $white;
    padding: 0 35px;
    position: relative;
    z-index: 0;
    h5,
    span {
      left: 50%;
      position: absolute;
      opacity: 0.8;
      padding: 3px 5px;
    }
    h5 {
      text-transform: capitalize;
      bottom: 60%;
      margin: 0;
      font-weight: 600;
      font-size: 1.2rem;
      color: $blue-grey;
    }
    span {
      font-size: 0.8em;
      color: $white;
      top: 50%;
    }
  }
}

// Resets
section:focus {
  outline: none !important;
}

button {
  &:focus,
  &:active {
    outline: none;
  }
}

// Preloader
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  z-index: 99;
}

// #status {
//     width: 200px;
//     height: 200px;
//     position: absolute;
//     left: 50%;
//     // centers the loading animation horizontally on the screen
//     top: 50%;
//     // centers the loading animation vertically on the screen
//     background-image: url(../images/loaders/rings.svg);
//     background-size: 80px 80px;
//     // path to loading animation
//     background-repeat: no-repeat;
//     background-position: center;
//     margin: -100px 0 0 -100px;
//     // width and height divided by two
// }

// Back to top button
#backtotop {
  position: fixed;
  right: 0;
  opacity: 0;
  visibility: hidden;
  bottom: 25px;
  margin: 0 25px 0 0;
  z-index: 9999;
  transition: 0.35s;
  transform: scale(0.7);
  transition: all 0.5s;
}

#backtotop.visible {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

#backtotop.visible a:hover {
  outline: none;
  opacity: 0.9;
  background: $secondary;
}

#backtotop a {
  outline: none;
  text-decoration: none;
  border: 0 none;
  display: block;
  width: 46px;
  height: 46px;
  background-color: $medium-grey;
  opacity: 1;
  transition: all 0.3s;
  border-radius: 50%;
  text-align: center;
  font-size: 26px;
}

body #backtotop a {
  outline: none;
  color: #fff;
}

#backtotop a:after {
  outline: none;
  content: '\f106';
  font-family: 'FontAwesome';
  position: relative;
  display: block;
  top: 50%;
  -webkit-transform: translateY(-55%);
  transform: translateY(-55%);
}

//Helpers
.is-disabled {
  pointer-events: none;
  opacity: 0.4;
  cursor: default !important;
}

.is-hidden {
  display: none !important;
}

.stuck {
  position: fixed !important;
  top: 0 !important;
  z-index: 2 !important;
}

.light-text {
  color: $white !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.ml-30 {
  margin-left: 30px;
}

.huge-pb {
  padding-bottom: 100px;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

//Input placeholders
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $placeholder;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $placeholder;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: $placeholder;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: $placeholder;
}
